<template>
  <v-row class="justify-center align-self-start">
    <v-col cols="12" md="8" lg="6">
      <v-card outlined :loading="loading">
        <v-card-title class="grey lighten-3 flex-column align-start">
          <div>Configuration de cet appareil</div>
          <div class="text-caption text-break">
            Chaque appareil, y compris un navigateur, doit être associé à une
            adresse et une licence pour pouvoir être utilisé.
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="subtitle-2 mb-2">Adresse associée à cet appareil</div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                label="Adresse"
                class="mb-2"
                v-model="addressId"
                :items="addresses"
                item-value="id"
                :item-text="
                  item => `${item.name} - ${item.city} - ${item.address}`
                "
                outlined
                dense
                :error-messages="addressesErrors"
              ></v-select>
            </v-col>
          </v-row>
          <div class="subtitle-2 mb-2">Licence associée à cet appareil</div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                label="Licence"
                class="mb-2"
                v-model="subscriptionId"
                :items="formattedSubscriptions"
                item-value="id"
                :item-text="item => `${item.id} - ${item.status}`"
                outlined
                dense
                :error-messages="subscriptionsErrors"
              ></v-select>
            </v-col>
            <v-col>
              <v-btn :to="{ name: 'Licences' }">Modifier les licences</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn dark :to="{ name: 'Home' }" exact>
            <v-icon left>mdi-chevron-left</v-icon>
            Retour
          </v-btn>
          <v-btn class="ml-auto" color="success" @click="save">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { requestService } from '@/services/request.service'

export default {
  name: 'Device',
  data() {
    return {
      addresses: [],
      addressId: null,
      loading: false,
      subscriptions: [],
      subscriptionId: null,
      addressesErrors: null,
      subscriptionsErrors: null,
      modified: false,
    }
  },
  computed: {
    formattedSubscriptions() {
      return this.subscriptions.map(subscription => {
        let status = 'Active'
        if (!subscription.usable) {
          status = `Terminée le ${this.formatDate(
            new Date(subscription.end_at),
          )}`
        } else if (subscription.end_at !== null) {
          status = `Se terminera le ${this.formatDate(
            new Date(subscription.end_at),
          )}`
        }
        return {
          id: subscription.id,
          status: status,
          disabled: !subscription.usable,
          usable: subscription.usable,
        }
      })
    },
  },
  methods: {
    fetchAddresses() {
      this.loading = true
      requestService.get('/api/address/').then(response => {
        this.addresses = response
        if (response.length) {
          const currentAddress = this.$root.get('currentAddressId')
          if (currentAddress !== null) {
            this.addressId = parseInt(currentAddress)
          }
          this.fetchSubscriptions()
        }
      })
    },
    fetchSubscriptions() {
      if (this.addressId === null) {
        this.loading = false
        return
      }
      requestService
        .get(`/api/payment/subscriptions/${this.addressId}`)
        .then(response => {
          this.subscriptions = response.subscriptions
          const currentSubscriptionId = this.$root.get('currentLicenceId')
          if (currentSubscriptionId !== null) {
            const currentSubscription = this.subscriptions.find(
              subscription =>
                subscription.id === parseInt(currentSubscriptionId),
            )
            if (currentSubscription && currentSubscription.usable) {
              this.subscriptionId = parseInt(currentSubscriptionId)
            }
          }
          this.loading = false
        })
    },
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    save() {
      this.addressesErrors = null
      this.subscriptionsErrors = null
      if (this.addressId === null) {
        return (this.addressesErrors = ['Veuillez choisir une adresse.'])
      }
      if (this.subscriptionId === null) {
        return (this.subscriptionsErrors = ['Veuillez choisir une licence.'])
      }
      this.$root.set('currentAddressId', this.addressId)
      this.$root.set('currentLicenceId', this.subscriptionId)
      this.$store.state.currentLicenceId = this.subscriptionId
      this.$store.dispatch('alert/success', 'Modifications enregistrées.')
      this.$store.dispatch('socket/disconnect')
    },
  },
  watch: {
    addressId() {
      this.modified = true
      this.addressesErrors = null
    },
    subscriptionId() {
      this.modified = true
      this.subscriptionsErrors = null
    },
  },
  mounted() {
    this.fetchAddresses()
  },
}
</script>

<style scoped></style>
